<script setup>
import { useRoute } from "vue-router";
import router from "../router";

// *Componentes do Inbox*
import SidebarApp from "@/components/inbox/components/SidebarApp.vue";
import MailBox from "@/components/inbox/components/MailBox.vue";
import MailContent from "@/components/inbox/components/MailContent.vue";
import MenuCompose from "@/components/inbox/components/MenuCompose.vue";

// *Importações e variáveis*
import { onMounted, watch, inject, ref } from "vue";
import config from "./../config/environment.js";
import io from "socket.io-client";
const socket = io(`${config.API_EMAILS}/`);
import axios from "axios";

const isLoading = ref(false);

const route = useRoute();

const {
  current_inbox,
  mails,
  inboxBusinessClients,
  currentPage,
  calculateIndexes,
  startIndex,
  endIndex,
  currentFilter,
  nextPage,
  selectedMail,
  previousPage,
  fetchEmails,
  ready,
  handlePageSelected,
  getInboxBusinessClientEmails,
  getInboxBusinessClientById,
  inbox,
  updateInbox,
  emailBody,
  filterEmails,
  fetchMetaInfo,
  inboxUserStatus,
} = inject("inbox");

// *Helpers*
import {
  base64UrlDecode,
  formatTime,
} from "../components/inbox/helpers/helpers";
import { base64UrlEncode } from "../components/inbox/helpers/helpers";

// *Variáveis e instâncias*
const languageModelGateway = inject("languageModelGateway");

const fnStatus = (status) => {
  console.log("Status da sessão: ", status);
  inboxUserStatus.value = status;

  console.log("inboxUserStatus agora é: ", inboxUserStatus.value);
};

const fn = (data) => {
  console.log("New message received: ", data);
  if (!data) {
    console.error("Received data is undefined");
    return;
  }

  if (data.message_type === "received" && currentFilter.value === "INBOX") {
    mails.value.unshift({
      avatar: data.from[0].name.charAt(0),
      uid: data.uid,
      from: data.from[0].name,
      fromAddress: data.from[0].address,
      to: data.to[0].name,
      toAddress: data.to[0].address,
      cc: data.cc,
      bcc: data.bcc,
      subject: data.subject,
      content: data.content_text,
      content_html: data.content_html,
      intentions: data.intentions,
      intentionText: data.intention_text,
      inboxOrigin: data.inbox_origin,
      date: formatTime(data.date),
      inbox_business_client_id: data.inbox_business_client_id,
    });
    currentTotalMessages.value += 1;
  }

  if (
    data.message_type === "sent" &&
    (currentFilter.value === "Sent" ||
      currentFilter.value === "[Gmail]/E-mails enviados")
  ) {
    mails.value.unshift({
      avatar: data.from[0].name.charAt(0),
      uid: data.uid,
      from: data.from[0].name,
      fromAddress: data.from[0].address,
      to: data.to[0].name,
      toAddress: data.to[0].address,
      cc: data.cc,
      bcc: data.bcc,
      subject: data.subject,
      content: data.content_text,
      content_html: data.content_html,
      intentions: data.intentions,
      intentionText: data.intention_text,
      inboxOrigin: data.inbox_origin,
      date: formatTime(data.date),
      inbox_business_client_id: data.inbox_business_client_id,
    });
    currentTotalMessages.value += 1;
  }
};

socket.on("connect", () => {
  console.log("Conexão com WebSocket API-Emails estabelecida...");
});

socket.on("disconnect", () => {
  console.log("Desconectado do Socket API-Emails...");
});

socket.on("error", function (error) {
  console.error("Erro na conexão com o Socket API-Emails:", error);
});

onMounted(async () => {
  inboxBusinessClients.value.length = 0;
  await getInboxBusinessClientEmails();
  router.push({
    path: route.params.messageId
      ? `/inbox/${current_inbox.value}/messages/${route.params.messageId}`
      : `/inbox/${current_inbox.value}/messages`,
    query: { p: route.query.p },
  });

  let response = await getInboxBusinessClientById();
  /*Refatoração para hook*/
  //inboxBusinessUserMail.value = response.data.details.user;

  currentPage.value = route.query.p || 1;

  await fetchEmails("INBOX");

  /*Refatoração para hook*/
  //inbox.value = response.data;

  await fetchMetaInfo();

  if (route.params.messageId) {
    try {
      isLoading.value = true;
      const { data } = await axios.get(
        `flash-growth-db-dev.azurewebsites.net/inbox-emails/message-id/${
          inbox.value.id
        }?message_id=${base64UrlDecode(route.params.messageId)}`,
        {
          headers: {
            Authorization: "VjNMgnNt9JaRtDc2tcPDWdGzni13",
          },
        }
      );

      console.log(data.data.message);

      if (data.data.message) {
        handleMailSelected({
          messageId: data.data.message.message_id,
          uid: data.data.message.uid,
          id: data.data.message.id,
          avatar: data.data.message.from[0].name.charAt(0),
          from: data.data.message.from[0].name,
          fromAddress: data.data.message.from[0].address,
          to: data.data.message.to[0].name,
          toAddress: data.data.message.to[0].address,
          subject: data.data.message.subject,
          content: data.data.message.content_text,
          content_html: data.data.message.content_html,
          intentions: data.data.message.intentions,
          intentionText: data.data.message.intention_text,
          inboxOrigin: data.data.message.inbox_origin,
          date: formatTime(data.data.message.date),
          mark_seen: data.data.message.mark_seen,
          references: JSON.parse(data.data.message.references),
          inbox_business_client_id: data.data.message.inbox_business_client_id,
        });
      }

      isLoading.value = false;
    } catch (error) {
      console.error(error);
    }
  }

  socket.emit("inbox_user_status", inbox.value.id);
  socket.on(`inbox_user_status_for_${inbox.value.id}`, fnStatus);

  socket.on(`new_message_${inbox.value.id}`, fn);
});

const handleMailSelected = (content) => {
  router.push({
    path: `/inbox/${inbox.value.id}/messages/${base64UrlEncode(
      content.messageId
    )}`,
    query: { p: route.query.p },
  });

  selectedMail.value = content;
};

watch(
  () => inbox.value,
  async (newValue, oldValue) => {
    if (!(Object.keys(oldValue).length === 0)) {
      router.push({
        path: `/inbox/${newValue.id}/messages`,
      });

      await fetchMetaInfo();

      currentPage.value = 1;

      await fetchEmails("received");

      //Antigos listeners
      socket.off(`new_message_${oldValue.id}`, fn);
      socket.off(`inbox_user_status_for_${inbox.value.id}`, fnStatus);

      //Checa o status da inbox atual
      socket.emit("inbox_user_status", inbox.value.id);
      socket.on(`inbox_user_status_for_${inbox.value.id}`, fnStatus);

      //Novo listener
      socket.on(`new_message_${newValue.id}`, fn);
    }
  }
);

watch(
  () => inbox.value,
  async () => {
    emailBody.value = {
      to: "",
      subject: "",
      html: "",
    };

    document.querySelector(".compose").classList.add("d-none");

    localStorage.setItem("currentInbox", inbox.value.id);
  }
);
</script>
<template>
  <SidebarApp
    :key="inbox.id"
    :updateInbox="updateInbox"
    :inboxBusinessClients="inboxBusinessClients"
    @pageSelected="handlePageSelected"
  />
  <MailBox
    v-if="inbox"
    :key="currentPage"
    :nextPage="nextPage"
    :previousPage="previousPage"
    :startIndex="startIndex"
    :endIndex="endIndex"
    :calculateIndexes="calculateIndexes"
    :currentPage="currentPage"
    :mails="mails"
    :ready="ready"
    :filterEmails="filterEmails"
    @mailSelected="handleMailSelected"
  />
  <MailContent
    :isLoading="isLoading"
    :generate-response="languageModelGateway.generateResponse"
    :detect-entities-and-intents="languageModelGateway.detectEntitiesAndIntents"
    :content="selectedMail"
  />
  <MenuCompose />
</template>
