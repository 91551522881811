<script setup>
//*Importações de componentes*

//*Importações do Vue*
import { inject, reactive, ref, computed, watch, onMounted } from "vue";
const { inbox, emailBody, signatures, batonPassEmails } = inject("inbox");

//*Outras importações*
import { toast } from "vue3-toastify";
const axios = require("axios");
import config from "../../../config/environment.js";

//*Editor de texto*
import Quill from "quill";
import "quill/dist/quill.snow.css";
let editor = null;

onMounted(() => {
  editor = new Quill("#editor", {
    theme: "snow",
  });

  editor.on("text-change", (_delta, _oldDelta, source) => {
    if (source == "user") {
      emailBody.value.text = editor.getText();
      emailBody.value.html = editor.root.innerHTML;
    }
  });
});

const setContent = (html) => {
  editor.clipboard.dangerouslyPasteHTML(editor.getLength(), html, "user");
  editor.setSelection(emailBody.value.text.length - 1);
};

const deleteContent = () => {
  const length = editor.getLength();
  editor.deleteText(0, length);
  // editor.setContents([]);
};

//*Importações de assets*
import attachmentSvg from "../../assets/svg/attachment.svg";
import fileSvg from "../../assets/svg/file.svg";

//*Declaração de variáveis e instâncias*
const attachedFiles = reactive([]);
const showCc = ref(false);
const showCco = ref(false);
const pickedSignature = ref(null);
const carbonCopy = computed(() => ({
  cc: emailBody.value.cc ? emailBody.value.cc.split(", ") : null,
  bcc: emailBody.value.bcc ? emailBody.value.bcc.split(", ") : null,
}));

const removeAttachment = (index) => {
  attachedFiles.splice(index, 1);
};

const sendEmail = async () => {
  try {
    if (!inbox.value || !emailBody.value.to || !emailBody.value.subject) {
      toast("Oops! Campo obrigatório não preenchido!", {
        position: "top-center",
        autoClose: 5000,
        type: "error",
        toastStyle: {
          "--toastify-icon-color-error": "#c60d31",
          "--toastify-color-error": "#c60d31",
          fontFamily: "Fivo Sans Modern Regular",
        },
        progressStyle: {
          backgroundColor: "#c60d31",
        },
      });
      return;
    }

    const formData = new FormData();

    for (const attachment of attachedFiles) {
      formData.append("file", attachment.file);
    }

    formData.append("id", inbox.value.id);
    formData.append("sender", inbox.value.name);
    formData.append("to", emailBody.value.to);
    formData.append("subject", emailBody.value.subject);
    formData.append("text", emailBody.value.text);
    formData.append("html", emailBody.value.html);

    if (carbonCopy.value.cc) {
      formData.append("cc", carbonCopy.value.cc);
    }

    if (carbonCopy.value.bcc) {
      formData.append("bcc", carbonCopy.value.bcc);
    }

    if (emailBody.value.type === "reply") {
      formData.append("inReplyTo", emailBody.value.messageId);
      formData.append("references", emailBody.value.references);

      const id = toast.loading("Enviando e-mail...", {
        position: "top-center",
        toastStyle: {
          fontFamily: "Fivo Sans Modern Regular",
        },
      });

      await axios.post(`${config.API_EMAILS}/email-sender/reply`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      toast.update(id, {
        render: "Enviado!",
        type: "success",
        isLoading: false,
        autoClose: 5000,
        toastStyle: {
          fontFamily: "Fivo Sans Modern Regular",
        },
        progressStyle: {
          backgroundColor: "green",
        },
      });

      emailBody.value.type = "send";
    } else {
      const id = toast.loading("Enviando e-mail...", {
        position: "top-center",
        toastStyle: {
          fontFamily: "Fivo Sans Modern Regular",
        },
      });

      await axios.post(`${config.API_EMAILS}/email-sender/send`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      toast.update(id, {
        render: "Enviado!",
        type: "success",
        isLoading: false,
        autoClose: 3500,
        toastStyle: {
          fontFamily: "Fivo Sans Modern Regular",
        },
        progressStyle: {
          backgroundColor: "green",
        },
      });
    }

    deleteContent();
    emailBody.value.cc = "";
    emailBody.value.bcc = "";
    emailBody.value.to = "";
    emailBody.value.subject = "";
    emailBody.value.html = "";
    emailBody.value.text = "";
    attachedFiles.length = 0;
  } catch (error) {
    toast("Oops! Erro ao enviar mensagem!", {
      position: "top-center",
      autoClose: 5000,
      type: "error",
      toastStyle: {
        "--toastify-icon-color-error": "#c60d31",
        "--toastify-color-error": "#c60d31",
        fontFamily: "Fivo Sans Modern Regular",
      },
      progressStyle: {
        backgroundColor: "#c60d31",
      },
    });
    console.error(error);
  }
};

const closeCompose = () => {
  deleteContent();
  emailBody.value.type = "send";
  emailBody.value.to = "";
  emailBody.value.subject = "";
  emailBody.value.html = "";
  emailBody.value.text = "";
  emailBody.value.cc = "";
  emailBody.value.bcc = "";

  document.querySelector(".menu-compose").classList.remove("d-none");
  document.querySelector(".compose").classList.remove("minimize");
  document.querySelector(".compose").classList.add("d-none");

  attachedFiles.length = 0;
};

const cleanCompose = () => {
  deleteContent();
  emailBody.value.to = "";
  emailBody.value.subject = "";
  emailBody.value.html = "";
  emailBody.value.text = "";
  emailBody.value.cc = "";
  emailBody.value.bcc = "";

  attachedFiles.length = 0;
};

const composeOpen = () => {
  document.querySelector(".menu-compose").classList.add("d-none");
  document.querySelector(".compose").classList.remove("d-none");
};

const composeMinimize = () => {
  document.querySelector(".compose").classList.toggle("minimize");
};

const composeFullscreen = () => {
  document.querySelector(".compose").classList.toggle("fullscreen");
};

const attachFile = async () => {
  const fileInput = document.getElementById("fileInput");

  if (fileInput.files && fileInput.files.length > 0) {
    for (let i = 0; i < fileInput.files.length; i++) {
      attachedFiles.push({
        filename: fileInput.files[i].name,
        file: fileInput.files[i],
        bytes: fileInput.files[i].size,
      });
    }
  }
};

watch(
  carbonCopy,
  (newVal) => {
    showCc.value = newVal.cc != null && newVal.cc !== "";
    showCco.value = newVal.bcc != null && newVal.bcc !== "";
  },
  { immediate: true }
);

const handleSignature = (key) => {
  pickedSignature.value = key;
  if (key) {
    const signature = `<br>${key}`;
    setContent(signature);
  }
};

const options = computed(() => {
  const defaultOption = {
    label: "Sem assinatura",
    key: null,
    disabled: pickedSignature.value === null,
  };

  const defaultOptions = [defaultOption];

  if (signatures.value) {
    const signatureOptions = signatures.value.map((signature) => ({
      label: signature.name,
      key: signature.value,
      disabled: pickedSignature.value === signature.value,
    }));

    return defaultOptions.concat(signatureOptions);
  }

  return defaultOptions;
});
</script>
<template>
  <a href="#" class="menu-compose" @click="composeOpen"
    ><i class="ri-pencil-line"></i
  ></a>
  <div class="compose d-none">
    <div class="compose-content">
      <div class="compose-header">
        <h6 class="compose-title">
          {{
            emailBody.type === "reply" ? "Escrever resposta" : "Nova mensagem"
          }}
        </h6>
        <nav class="nav">
          <a href="#" class="nav-link nav-link-minimize"
            ><i class="ri-subtract-line" @click="composeMinimize"></i
          ></a>
          <a href="#" class="nav-link nav-link-fullscreen">
            <i class="ri-fullscreen-line" @click="composeFullscreen"></i>
            <i class="ri-fullscreen-exit-line" @click="composeFullscreen"></i>
          </a>
          <a href="#" class="nav-link nav-link-close">
            <i class="ri-close-line" @click="closeCompose"></i
          ></a>
        </nav>
      </div>
      <div class="compose-body">
        <div class="form-group">
          <datalist id="baton-pass-emails">
            <option
              v-for="mail in batonPassEmails"
              :key="mail"
              :value="mail"
            ></option>
          </datalist>

          <input
            type="text"
            v-model="emailBody.to"
            class="form-control"
            placeholder="Destinatário"
          />
          <div class="cc-cco-container">
            <span
              class="cc-cco-text"
              :class="{ underline: showCc }"
              @click="showCc = !showCc"
              >Cc</span
            >
            <span
              class="cc-cco-text"
              :class="{ underline: showCco }"
              @click="showCco = !showCco"
              >Cco</span
            >
          </div>
        </div>
        <div v-show="showCc" class="form-group">
          <input
            type="text"
            list="baton-pass-emails"
            v-model="emailBody.cc"
            class="form-control"
            placeholder="Cc"
          />
        </div>
        <div v-show="showCco" class="form-group">
          <input
            type="text"
            v-model="emailBody.bcc"
            list="baton-pass-emails"
            class="form-control"
            placeholder="Cco"
          />
        </div>
        <div class="form-group">
          <input
            type="text"
            v-model="emailBody.subject"
            class="form-control"
            placeholder="Assunto"
          />
        </div>
        <div class="form-group">
          <div id="editor"></div>
        </div>
      </div>
      <div class="compose-footer">
        <div class="left-footer">
          <button
            @click="sendEmail()"
            type="submit"
            class="btn btn-primary btn-icontext"
          >
            Enviar
            <svg
              class="button-icon"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              aria-hidden="true"
              role="img"
              width="1em"
              height="1em"
              viewBox="0 0 24 24"
            >
              <path
                fill="currentColor"
                d="M3.402 6.673c-.26-2.334 2.143-4.048 4.266-3.042l11.944 5.658c2.288 1.083 2.288 4.339 0 5.422L7.668 20.37c-2.123 1.006-4.525-.708-4.266-3.042L3.882 13H12a1 1 0 1 0 0-2H3.883z"
              ></path>
            </svg>
          </button>
          <label
            class="file-upload-label"
            @change="attachFile"
            aria-label="Anexar arquivo"
          >
            <img alt="attachment" :src="attachmentSvg" />
            <span class="tooltip-text">Anexar</span>
            <input
              type="file"
              id="fileInput"
              class="hidden"
              name="file-input"
              multiple
            />
          </label>
          <div class="middle-footer">
            <div class="attached-files">
              <div
                class="attached-file-wrapper"
                v-for="(file, index) in attachedFiles"
                :key="index"
              >
                <span class="tooltip-text">{{ file.filename }}</span>
                <img :src="fileSvg" alt="anexo" class="attached-file" />
                <button
                  class="remove-file-btn"
                  @click="removeAttachment(index)"
                >
                  ×
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="right-footer">
          <n-dropdown
            placement="bottom-start"
            trigger="click"
            size="small"
            :options="options"
            @select="handleSignature"
          >
            <svg
              style="outline: none; box-shadow: none"
              class="signature-icon"
              width="15px"
              height="15px"
              fill="#6b748f"
              viewBox="0 0 512 512"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
              <g
                id="SVGRepo_tracerCarrier"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></g>
              <g id="SVGRepo_iconCarrier">
                <path
                  d="M497.94 74.17l-60.11-60.11c-18.75-18.75-49.16-18.75-67.91 0l-56.55 56.55 128.02 128.02 56.55-56.55c18.75-18.75 18.75-49.15 0-67.91zm-246.8-20.53c-15.62-15.62-40.94-15.62-56.56 0L75.8 172.43c-6.25 6.25-6.25 16.38 0 22.62l22.63 22.63c6.25 6.25 16.38 6.25 22.63 0l101.82-101.82 22.63 22.62L93.95 290.03A327.038 327.038 0 0 0 .17 485.11l-.03.23c-1.7 15.28 11.21 28.2 26.49 26.51a327.02 327.02 0 0 0 195.34-93.8l196.79-196.79-82.77-82.77-84.85-84.85z"
                ></path>
              </g>
            </svg>
          </n-dropdown>

          <button class="btn btn-white btn-icon" @click="cleanCompose">
            <i class="ri-delete-bin-line"></i>
          </button>
        </div>
      </div>
      <!-- compose footer -->
    </div>
  </div>
</template>
<style scoped>
.menu-compose {
  position: fixed;
  bottom: 15px;
  right: 15px;
  z-index: 800;
  width: 56px;
  height: 56px;
  border-radius: 100%;
  background-color: #dc0f37;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  transition: all 0.2s;
  box-shadow: 0 1px 1px rgba(204, 207, 217, 0.25),
    0 2px 2px rgba(204, 207, 217, 0.2), 0 4px 4px rgba(204, 207, 217, 0.15),
    0 8px 8px rgba(204, 207, 217, 0.1), 0 16px 16px rgba(204, 207, 217, 0.05);
}

@media (prefers-reduced-motion: reduce) {
  .menu-compose {
    transition: none;
  }
}

@media (min-width: 768px) {
  .menu-compose {
    right: 25px;
    bottom: 25px;
  }
}

.menu-compose:hover,
.menu-compose:focus {
  transform: scale(1.1);
  color: #fff;
}

.menu-compose i {
  font-size: 28px;
  font-weight: 400;
  line-height: 1;
}

.compose {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 1000;
}

@media (min-width: 768px) {
  .compose {
    padding: 20px 20px 0;
    max-height: 100vh;
    overflow-y: auto;
  }
}

.compose.minimize .compose-content {
  width: 280px;
}

.compose.minimize .compose-body,
.compose.minimize .nav-toolbar,
.compose.minimize .compose-footer {
  display: none;
}

.compose.fullscreen:not(.minimize) {
  top: 0;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(33, 35, 41, 0.5);
}

.compose.fullscreen:not(.minimize) .compose-content {
  width: 80%;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  box-shadow: 0 1px 2px rgba(52, 55, 64, 0.07), 0 2px 4px rgba(52, 55, 64, 0.07),
    0 4px 8px rgba(52, 55, 64, 0.07), 0 8px 16px rgba(52, 55, 64, 0.07),
    0 16px 32px rgba(52, 55, 64, 0.07), 0 32px 64px rgba(52, 55, 64, 0.07);
}

.compose.fullscreen:not(.minimize) .nav-link-fullscreen i:first-child {
  display: none;
}

.compose.fullscreen:not(.minimize) .nav-link-fullscreen i:last-child {
  display: block;
}

.compose-content {
  position: relative;
  width: 100vw;
  height: 75vh;
  display: flex;
  flex-direction: column;
}

.compose-content {
  width: 546px;
  height: auto;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  box-shadow: 0 1px 2px rgba(174, 177, 189, 0.07),
    0 2px 4px rgba(174, 177, 189, 0.07), 0 4px 8px rgba(174, 177, 189, 0.07),
    0 8px 16px rgba(174, 177, 189, 0.07), 0 16px 32px rgba(174, 177, 189, 0.07),
    0 32px 64px rgba(174, 177, 189, 0.07);
}

.compose-header {
  height: 48px;
  padding: 0 15px;
  background-color: #dc0f37;
  color: #fff;
  display: flex;
  align-items: center;
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  cursor: pointer;
}

.compose-header h6,
.compose-header .h6 {
  flex: 1;
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 0;
}

.compose-header .nav {
  margin-top: 1.5px;
  margin-left: 10px;
  align-items: center;
}

.compose-header .nav-link {
  padding: 0;
  font-size: 18px;
  color: rgba(255, 255, 255, 0.75);
  line-height: 1;
}

.compose-header .nav-link:not(:last-child) {
  display: none;
}

@media (min-width: 768px) {
  .compose-header .nav-link:not(:last-child) {
    display: block;
  }
}

.compose-header .nav-link + .nav-link {
  margin-left: 8px;
}

.compose-header .nav-link-fullscreen i:last-child {
  display: none;
}

.compose-body {
  flex: 1;
  background-color: #fff;
  border-bottom: 1px solid #f0f2f7;
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.compose-body .form-group + .form-group {
  margin-top: 15px;
}

.compose-body .form-group:last-child {
  flex: 1;
}

.compose-body input.form-control {
  font-size: 14px;
  padding: 0 0 12px;
  border-radius: 0;
  border-width: 0 0 1px;
  border-bottom-color: #dde1ed;
  color: #212329;
}

.compose-body input.form-control::placeholder {
  color: #a1a8bb;
  text-shadow: none;
}

.compose-body input.form-control:focus {
  box-shadow: none;
}

.compose-body textarea.form-control {
  padding: 0;
  font-size: 14px;
  border-radius: 0;
  border-width: 0;
  color: #212329;
  resize: none;
  height: 100%;
}

.compose-body textarea.form-control:focus {
  box-shadow: none;
}

.compose-footer {
  background-color: #fff;
  border-top: 1px solid #f0f2f7;
  padding: 12px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
}

.compose-footer .btn-icontext {
  padding-left: 15px;
  padding-right: 15px;
}

.compose-footer .btn-icontext i {
  font-size: 16px;
}

.btn-icontext {
  border-radius: 6px;
  gap: 5px;
}

.hidden {
  display: none;
}

.file-upload-label:hover {
  background-color: #e0e0e0;
}

.middle-footer {
  display: flex;
}

.left-footer {
  display: flex;
  gap: 10px;
  align-items: center;
}

.middle-footer {
  display: flex;
  align-items: center;
}

.file-upload-label img {
  display: block;
  padding: 4px;
  border-radius: 50%;
}

.file-upload-label {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 3px;
  border-radius: 50%;
  transition: 0.2s ease;
}

.file-upload-label:hover {
  background-color: #e0e0e0;
}

.attached-files img {
  margin-right: 10px;
  cursor: pointer;
  transition: transform 0.2s ease;
  padding: 4px;
  border-radius: 50%;
}

.attached-files img:hover {
  background-color: #e0e0e0;
}

.btn-primary:hover,
.btn-primary {
  background-color: #c60d31;
  border-color: #c60d31;
}

.btn-primary:active {
  background-color: #c60d31;
  border-color: #c60d31;
  transform: translateY(2px);
}

.attached-files {
  position: relative;
}

.attached-file-wrapper {
  position: relative;
  display: inline-block;
}

.tooltip-text {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  white-space: nowrap;
  z-index: 1000;
  bottom: calc(100% + 8px);
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.2s ease;
}

.attached-file-wrapper:hover .tooltip-text {
  opacity: 1;
}

.file-upload-label:hover .tooltip-text {
  opacity: 1;
}

.file-upload-label {
  position: relative;
  display: inline-block;
}

.file-upload-label:hover .tooltip-text {
  opacity: 1;
}

.tooltip-text {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  white-space: nowrap;
  z-index: 1000;
  bottom: 45px;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.2s ease;
}

.file-upload-label img {
  display: block;
  width: 24px;
  height: 24px;
}

.file-upload-label:hover .tooltip-text {
  opacity: 1;
}

.attached-file-wrapper {
  position: relative;
  display: inline-block;
}

.remove-file-btn {
  position: absolute;
  top: 4px;
  right: 8px;
  color: #c60d31;
  border: none;
  font-size: 11px;
  cursor: pointer;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
}

.remove-file-btn:hover {
  transform: scale(1.3);
}
.form-group {
  position: relative;
  width: 100%;
}

.form-control {
  width: 100%;
  padding-right: 80px;
  box-sizing: border-box;
}

.cc-cco-container {
  color: #c60d31;
  font-size: 14px;
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-40%);
  display: flex;
  gap: 5px;
}

.cc-cco-text:hover {
  cursor: pointer;
  text-decoration: underline;
}

.cc-cco-text.underline {
  text-decoration: underline;
}

.right-footer {
  display: flex;
  gap: 10px;
  align-items: center;
  flex-direction: row;
}

.signature-icon {
  cursor: pointer;
}
</style>
