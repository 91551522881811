<script setup>
import { ref, watch } from "vue";

const props = defineProps({
  role: {
    type: String,
    default: "",
  },
  isOpen: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["input", "save"]);
const roleInput = ref(props.role);

watch(
  () => props.role,
  (newRole) => {
    roleInput.value = newRole;
  }
);

const updateIsOpen = () => {
  emit("input", false);
};

const save = () => {
  emit("save", roleInput.value);
  updateIsOpen();
};
</script>

<template>
  <teleport to="body">
    <div class="modal" v-if="props.isOpen">
      <div class="modal-content">
        <div class="modal-bar">
          <h5>Construtor de comando</h5>
          <img
            @click="updateIsOpen"
            alt="image"
            src="https://api.iconify.design/icon-park-outline/close-one.svg?color=white"
            class="modal-image"
          />
        </div>
        <div class="container-content">
          <span>Papel Comando</span>
          <textarea
            v-model="roleInput"
            placeholder="Digite o comando e diretrizes que a inteligência artificial irá seguir."
          ></textarea>
          <button class="button" @click="save">
            Gerar resposta
            <svg
              class="button-icon"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              aria-hidden="true"
              role="img"
              width="1em"
              height="1em"
              viewBox="0 0 24 24"
            >
              <path
                fill="currentColor"
                fill-rule="evenodd"
                d="M3.402 6.673c-.26-2.334 2.143-4.048 4.266-3.042l11.944 5.658c2.288 1.083 2.288 4.339 0 5.422L7.668 20.37c-2.123 1.006-4.525-.708-4.266-3.042L3.882 13H12a1 1 0 1 0 0-2H3.883z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </button>
        </div>
      </div>
    </div>
  </teleport>
</template>

<style>
h5 {
  color: white;
  font-size: 18px;
  font-weight: 500;
}

.modal {
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  width: 60%;
  height: 70%;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
}
.modal-bar {
  width: 100%;
  height: 60px;
  padding: 12px 8px 12px 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 15px;
  background-color: #dc0f37;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.modal-image {
  top: 8px;
  right: 0px;
  width: 25px;
  cursor: pointer;
  position: absolute;
  justify-self: flex-end;
  margin-right: 10px;
}
.container-content {
  display: flex;
  flex-direction: column;
  width: 80%;
  height: 80%;
  align-items: center;
  justify-content: center;
}
.container-content textarea {
  width: 100%;
  height: 150px;
  border-radius: 8px;
  padding: 10px;
  font-size: 16px;
  resize: none;
}
.modal-text {
  font-size: 20px;
  font-weight: 500;
  text-transform: uppercase;
}
.button {
  display: flex;
  width: 160px;
  cursor: pointer;
  height: 40px;
  color: #fff;
  padding: 10px;
  gap: 5px;
  margin-top: 20px;
  border-radius: 10px;
  background-color: #dc0f37;
  border: none;
  align-items: center;
  justify-content: center;
}
.button:active {
  box-shadow: 0 4px #ea6f87;
  transform: translateY(4px);
}

.button-icon {
  width: 1.2em;
  height: 1.3em;
}

.container-content textarea {
  width: 1100px;
  height: 400px;
  outline: none;
  border-radius: 8px;
  padding: 10px;
  font-size: 16px;
  resize: none;
}
</style>
