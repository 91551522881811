import "../public/lib/remixicon/fonts/remixicon.css";
import "../public/assets/css/style.css";
import "../public/lib/jquery/jquery.min.js";
import "../public/lib/bootstrap/js/bootstrap.bundle.min.js";
import "../public/lib/perfect-scrollbar/perfect-scrollbar.min.js";
import "vue3-toastify/dist/index.css";

import naive from "naive-ui";
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { createPinia } from "pinia";
import shadow from "vue-shadow-dom";
import { LanguageModelGateway } from "./components/inbox/providers/language-model.gateway";

const pinia = createPinia();
const app = createApp(App);

const languageModelGateway = new LanguageModelGateway();

app.provide("languageModelGateway", languageModelGateway);
app.use(naive);
app.use(shadow);
app.use(pinia);
app.use(router);
app.mount("#app");
