<template>
  <div class="kpi-container">
    <div class="kpi" v-for="(value, key) in kpis" :key="key">
      <h3>{{ translateKeyTerms(key) }}</h3>
      <p>{{ value }}</p>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  kpis: Object,
});

const translateKeyTerms = (key) => {
  const term = {
    requests: "Solicitações",
    delivered: "Entregues",
    opens: "Aberturas",
    unique_opens: "Aberturas únicas",
    clicks: "Cliques",
    unique_clicks: "Cliques Únicos",
    unsubscribes: "Desinscrição",
    bounces: "Devolvidos",
    spam_reports: "Denúncias Spam",
    blocks: "Bloqueios",
    bounce_drops: "Quedas de Devolvidos",
    spam_report_drops: "Quedas de Denúncias Spam",
    unsubscribe_drops: "Quedas de Desinscrição",
    schedulings: "Agendamentos",
  };

  return term[key] || key;
};
</script>

<style scoped>
.kpi-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-bottom: 20px;
}

.kpi {
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-left: 5px solid #dc3545;
  border-radius: 10px;
  padding: 10px 20px;
  margin: 10px;
  flex: 1 1 200px;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.kpi h3 {
  color: #ff4d4d;
  font-size: 12.6px;
  margin-top: 15px;
  font-weight: 600;
  display: flex;
  justify-content: flex-start;
}

.kpi p {
  display: flex;
  justify-content: flex-start;
  font-size: 1.2em;
  color: #333;
  font-weight: 600;
}
</style>
