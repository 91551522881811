import axios from "axios";

export class FetchAPI {
  constructor(baseUrl) {
    this.baseUrl = baseUrl;
    this.headers = { Authorization: "VjNMgnNt9JaRtDc2tcPDWdGzni13" };
    this.defaultOptions = { headers: this.headers };
  }

  async sendRequest(endpoint, method = "GET", data = null) {
    const requestOptions = {
      ...this.defaultOptions,
      method,
      url: this.baseUrl + endpoint,
    };
    if (data) requestOptions.data = data;

    try {
      const response = await axios(requestOptions);
      return response.data;
    } catch (error) {
      console.error(`Error sending ${method} request to ${endpoint}:`, error);
      throw error;
    }
  }

  async getAllEmailsPaginated(
    inboxBusinessId = null,
    page = 1,
    size = 15,
    openedInbox = null,
    intentionLabel = null
  ) {
    let endpoint;

    if (openedInbox)
      endpoint = `/inbox-emails/v2?inbox_business_id=${inboxBusinessId}&inbox_origin=${openedInbox}&label_filters=auto_reply&page=${page}&size=${size}`;

    if (intentionLabel)
      endpoint = `/inbox-emails/v2?inbox_business_id=${inboxBusinessId}&intention_label=${intentionLabel}&page=${page}&size=${size}`;

    return await this.sendRequest(endpoint);
  }

  async getAllFilteredEmailsPaginated(
    data,
    inboxBusinessId,
    page = 1,
    size = 15
  ) {
    const endpoint = `/inbox-emails/search?inbox_business_client_id=${inboxBusinessId}&page=${page}&size=${size}`;

    return await this.sendRequest(endpoint, "POST", data);
  }

  async getAllInboxBusinessClients() {
    const endpoint = "/inbox-business-clients";

    return await this.sendRequest(endpoint);
  }

  async getInboxBusinessClientById(id) {
    const endpoint = `/inbox-business-clients/${id}`;

    return await this.sendRequest(endpoint);
  }

  async createInboxBusinessClient(name, user, password) {
    const endpoint = "/inbox-business-clients";
    const data = {
      name,
      details: { user, password },
      business_client_id: null,
      oportunity_id: null,
    };

    return await this.sendRequest(endpoint, "POST", data);
  }

  async addInboxBusinessClientAndIMAP(name, user, password) {
    const endpoint = "/email-reader";
    const data = {
      name,
      details: { user, password },
      business_client_id: null,
      oportunity_id: null,
    };

    return await this.sendRequest(endpoint, "POST", data);
  }
}
