<script setup>
// *Importações do vue*
import { defineProps, defineEmits, ref, inject } from "vue";
import { toast } from "vue3-toastify";
import { useRoute } from "vue-router";
import router from "../../../router";

const route = useRoute();

// *Importações de componentes*
import Dropdown from "../../common/Dropdown.vue";

const emit = defineEmits();

const page = ref("Caixa de Entrada");

const { inbox, metaInfo, inboxMessage, currentFilter } = inject("inbox");

const props = defineProps({
  inboxBusinessClients: { type: Array, default: [] },
  updateInbox: { type: Function, default: () => {} },
});

const selectPage = (Page) => {
  page.value = Page;

  emit("pageSelected", Page);

  const currentRoute = router.currentRoute.value.path;
  const inboxId = inbox.value.id;

  if (currentRoute === `/inbox/${inboxId}/dashboard`) {
    router.push(`/inbox/${route.params.id}`);
  }
};

const goToDashboard = () => {
  if (
    metaInfo[0] &&
    metaInfo[0].mailing_tags &&
    metaInfo[0].mailing_tags.length > 0
  ) {
    router.push({
      path: `/inbox/${inbox.value.id}/dashboard`,
    });

    return;
  }

  router.push(`/inbox/${inbox.value.id}/messages`);

  toast("Oops! Este inbox não possui um dashboard. Selecione outro!", {
    position: "top-center",
    autoClose: 5000,
    type: "error",
    toastStyle: {
      "--toastify-icon-color-error": "#c60d31",
      "--toastify-color-error": "#c60d31",
      fontFamily: "Fivo Sans Modern Regular",
    },
    progressStyle: {
      backgroundColor: "#c60d31",
    },
  });
};

const goInboxView = () => {
  router.push(`/inbox/${inbox.value.id}/messages`);
};
</script>

<template>
  <div class="sidebar">
    <div class="sidebar-header" @click="goInboxView">
      <img
        alt="image"
        src="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/87315075-d3a6-4da7-9261-799af583a5f7/3e8475b4-c786-4069-bdc8-cabac908f3e4?org_if_sml=1&h=200"
        class="sidebar-logo"
      />
    </div>
    <div class="sidebar-body">
      <nav class="nav flex-column mb-5">
        <Dropdown :options="props.inboxBusinessClients" />
        <a
          v-if="
            metaInfo[0] &&
            metaInfo[0].mailing_tags &&
            metaInfo[0].mailing_tags.length > 0
          "
          v-show="Object.keys(inbox).length !== 0"
          href="#"
          class="nav-link"
          @click.prevent="goToDashboard"
        >
          <svg
            height="20"
            width="19"
            fill="#000000"
            viewBox="0 0 24 24"
            id="dashboard-alt"
            data-name="Flat Color"
            xmlns="http://www.w3.org/2000/svg"
            class="icon flat-color"
          >
            <g id="SVGRepo_bgCarrier" stroke-whiteidth="0"></g>
            <g
              id="SVGRepo_tracerCarrier"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></g>
            <g id="SVGRepo_iconCarrier">
              <path
                id="primary"
                d="M2,8V4A2,2,0,0,1,4,2H20a2,2,0,0,1,2,2V8Zm14,2V22h4a2,2,0,0,0,2-2V10Z"
                style="fill: white"
              ></path>
              <path
                id="secondary"
                d="M14,10H2V20a2,2,0,0,0,2,2H14Z"
                style="fill: white"
              ></path>
            </g>
          </svg>
          <span>Dashboard</span>
        </a>
        <span v-else class="nav-link disable">
          <svg
            height="20"
            width="19"
            fill="#000000"
            viewBox="0 0 24 24"
            id="dashboard-alt"
            data-name="Flat Color"
            xmlns="http://www.w3.org/2000/svg"
            class="icon flat-color"
          >
            <g id="SVGRepo_bgCarrier" stroke-whiteidth="0"></g>
            <g
              id="SVGRepo_tracerCarrier"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></g>
            <g id="SVGRepo_iconCarrier">
              <path
                id="primary"
                d="M2,8V4A2,2,0,0,1,4,2H20a2,2,0,0,1,2,2V8Zm14,2V22h4a2,2,0,0,0,2-2V10Z"
                style="fill: white"
              ></path>
              <path
                id="secondary"
                d="M14,10H2V20a2,2,0,0,0,2,2H14Z"
                style="fill: white"
              ></path>
            </g>
          </svg>
          <span class="disable">Dashboard</span>
        </span>
        <hr class="split-line" />

        <div
          v-if="Object.keys(inbox).length === 0"
          class="sidebar-mailbox-tree-loading"
        >
          <div class="nav-link"></div>
          <div class="nav-link"></div>
        </div>

        <div v-else class="sidebar-mailbox-tree">
          <a
            class="inbox-selection"
            @click="selectPage('INBOX')"
            :class="{ 'nav-link': true, active: currentFilter === 'INBOX' }"
          >
            <i class="ri-inbox-line"></i>
            <span>Caixa de Entrada</span>
            <small>{{
              inboxMessage.unReadInbox > 0 ? inboxMessage.unReadInbox : ""
            }}</small>
          </a>
          <a
            class="inbox-selection"
            @click="selectPage('Sent')"
            :class="{
              'nav-link': true,
              active:
                currentFilter === 'Sent' ||
                currentFilter === '[Gmail]/E-mails enviados',
            }"
          >
            <i class="ri-send-plane-2-line"></i>
            <span>Enviados</span>
            <small>{{
              inboxMessage.unReadSent > 0 ? inboxMessage.unReadSent : ""
            }}</small>
          </a>
          <a
            class="inbox-selection"
            @click="selectPage('Arquivados')"
            :class="{
              'nav-link': true,
              active: currentFilter === 'Arquivados',
            }"
          >
            <i class="ri-inbox-line"></i>
            <span>Arquivados</span>
            <small>{{
              inboxMessage.unReadArchived > 0 ? inboxMessage.unReadArchived : ""
            }}</small>
          </a>

          <a
            class="inbox-selection"
            @click="selectPage('Junk')"
            :class="{
              'nav-link': true,
              active:
                currentFilter === 'Junk' || currentFilter === '[Gmail]/Spam',
            }"
          >
            <i class="ri-inbox-line"></i>
            <span>Spam</span>
            <small>{{
              inboxMessage.unReadSpam > 0 ? inboxMessage.unReadSpam : ""
            }}</small>
          </a>
        </div>
      </nav>
      <div class="sidebar-label">Categorias</div>
      <nav class="nav flex-column mb-5">
        <a
          @click="selectPage('Scheduling')"
          href="#"
          :class="{ 'nav-link': true, active: currentFilter === 'Scheduling' }"
          ><i class="ri-price-tag-line"></i> <span>Agendamentos</span>
          <small>{{
            inboxMessage.unReadScheduling ? inboxMessage.unReadScheduling : ""
          }}</small>
        </a>
        <a
          @click="selectPage('Referral')"
          href="#"
          :class="{ 'nav-link': true, active: currentFilter === 'Referral' }"
          ><i class="ri-price-tag-line"></i> <span>Indicações</span>
          <small>{{
            inboxMessage.unReadReferral ? inboxMessage.unReadReferral : ""
          }}</small>
        </a>
        <a
          @click="selectPage('Presentation')"
          href="#"
          :class="{
            'nav-link': true,
            active: currentFilter === 'Presentation',
          }"
          ><i class="ri-price-tag-line"></i> <span>Apresentações</span>
          <small>{{
            inboxMessage.unReadPresentation
              ? inboxMessage.unReadPresentation
              : ""
          }}</small>
        </a>
        <a
          @click="selectPage('Prospecting')"
          href="#"
          :class="{ 'nav-link': true, active: currentFilter === 'Prospecting' }"
          ><i class="ri-price-tag-line"></i> <span>Prospecções</span>
          <small>{{
            inboxMessage.unReadProspecting ? inboxMessage.unReadProspecting : ""
          }}</small>
        </a>

        <a
          @click="selectPage('Mailing_exclusion')"
          href="#"
          :class="{
            'nav-link': true,
            active: currentFilter === 'Mailing_exclusion',
          }"
          ><i class="ri-price-tag-line"></i> <span>Exclusões de e-mails</span>
          <small>{{
            inboxMessage.unReadMailingExclusion
              ? inboxMessage.unReadMailingExclusion
              : ""
          }}</small>
        </a>
        <a
          @click="selectPage('no_interest')"
          href="#"
          :class="{ 'nav-link': true, active: currentFilter === 'no_interest' }"
          ><i class="ri-price-tag-line"></i> <span>Sem interesse</span>
          <small>{{
            inboxMessage.unReadNoInterest ? inboxMessage.unReadNoInterest : ""
          }}</small>
        </a>
        <a
          @click="selectPage('auto_reply')"
          href="#"
          :class="{ 'nav-link': true, active: currentFilter === 'auto_reply' }"
          ><i class="ri-price-tag-line"></i> <span>Respostas automáticas</span>
          <small>{{
            inboxMessage.unReadAutoReply ? inboxMessage.unReadAutoReply : ""
          }}</small>
        </a>
      </nav>
    </div>
  </div>
</template>

<style scoped>
@media (max-width: 767.98px) {
  .sidebar-show {
    overflow: hidden;
  }

  .sidebar-show .sidebar {
    left: 0;
  }

  .sidebar-show .mailbox {
    transform: translateX(230px);
  }

  .sidebar-show .backdrop {
    opacity: 1;
    visibility: visible;
  }
}

@media (min-width: 768px) {
  .sidebar-hide .sidebar {
    left: -230px;
  }

  .sidebar-hide .mailbox {
    left: 0;
  }

  .sidebar-hide .mailcontent {
    width: 100vw;
  }
}

@media (min-width: 1200px) {
  .sidebar-hide .mailcontent {
    left: 390px;
    width: auto;
  }
}

.sidebar {
  position: fixed;
  width: 250px;
  height: 100%;
  background-color: #dc0f37;
  z-index: 1000;
}

@media (prefers-reduced-motion: reduce) {
  .sidebar {
    transition: none;
  }
}

@media (min-width: 768px) {
  .sidebar {
    left: 0;
  }
}

.sidebar-header {
  cursor: pointer;
  height: 80px;
  display: flex;
  align-items: center;
  padding: 4px 25px 0;
}

.sidebar-logo {
  width: 180px;
}

.sidebar-body {
  padding: 14px 25px 25px;
  position: relative;
}

.sidebar-body .nav-link {
  height: 40px;
  padding: 0;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.7);
  position: relative;
}

.sidebar-body .nav-link::before {
  content: "";
  position: absolute;
  top: 0;
  left: -10px;
  right: -10px;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.16);
  border-radius: 5px;
  opacity: 0;
  visibility: hidden;
  transition: all 0.2s;
}

@media (prefers-reduced-motion: reduce) {
  .sidebar-body .nav-link::before {
    transition: none;
  }
}

.sidebar-body .nav-link:hover,
.sidebar-body .nav-link:focus {
  color: #fff;
}

.sidebar-body .nav-link:hover::before,
.sidebar-body .nav-link:hover::before,
.sidebar-body .nav-link:focus::before,
.sidebar-body .nav-link:focus::before {
  visibility: visible;
  opacity: 0.6;
}

.sidebar-body .nav-link.active {
  color: #ff9900;
}

.sidebar-body .nav-link.active::before {
  opacity: 1;
  visibility: visible;
  background-color: #fff;
}

.sidebar-body .nav-link.active:hover::before,
.sidebar-body .nav-link.active:focus::before {
  opacity: 1;
}

.sidebar-body .nav-link i {
  font-size: 20px;
  font-weight: 400;
  position: relative;
}

.sidebar-body .nav-link span {
  position: relative;
  display: block;
  margin-left: 10px;
}

.sidebar-body .nav-link + .nav-link {
  margin-top: 1px;
}

.sidebar-body .nav-link small,
.sidebar-body .nav-link .small {
  margin-left: auto;
  font-weight: 400;
  font-size: 13px;
  position: relative;
  opacity: 0.7;
}

.sidebar-label {
  display: block;
  font-size: 11px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.4);
  text-transform: uppercase;
  letter-spacing: 0.5px;
  margin-bottom: 10px;
}

.ri-inbox-line {
  cursor: pointer;
}

.mail-select {
  width: 100%;
  height: 40px;
  margin-bottom: 20px;
  padding: 0 15px;
  border: none;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.1);
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  cursor: pointer;
  outline: none;
}

.mail-select option {
  color: #dc0f37;
  font-weight: bold;
}

.split-line {
  height: 1px;
  background-color: #fff;
}

/* LOADING DE ESQUELETO PARA .sidebar-mailbox-tree-loading */

.sidebar-mailbox-tree-loading .nav-link {
  display: flex;
  background: #b00e2d;
  border-radius: 5px;
  width: 205px;
  height: 40px;
  margin-bottom: 2px;
  position: relative;
  overflow: hidden;
}

.sidebar-mailbox-tree-loading .nav-link::before {
  height: 100%;
  width: 100%;
  background-image: linear-gradient(
    to right,
    #b00e2d 0%,
    rgba(255, 255, 255, 0.1) 20%,
    #b00e2d 40%,
    #b00e2d 100%
  );
  background-repeat: no-repeat;
  background-size: 450px 400px;
}

.nav-link .disable:hover {
  cursor: default;
}

.disable {
  cursor: default;
}

.inbox-selection {
  cursor: pointer;
}
</style>
