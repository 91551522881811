<template>
  <div class="tags-input">
    <i class="ri-search-line"></i>
    <span class="tag" v-for="(tag, index) in tags" :key="index">
      {{ limitCharacters(tag) }}
      <span @click="removeTag(index)">x</span>
    </span>
    <input
      type="text"
      v-model="newTag"
      @keydown.enter.prevent="addTag"
      :placeholder="tags.length > 0 ? '' : 'Pesquisar e-mail...'"
      :disabled="tags.length > 0"
    />
  </div>
</template>

<script setup>
import { ref } from "vue";

const limitCharacters = (text) => {
  return text.length > 20 ? text.substring(0, 20) + "..." : text;
};

const props = defineProps({
  value: {
    type: Array,
    default: () => [],
  },
});

const emit = defineEmits(["input"]);

const newTag = ref("");

const tags = ref([...props.value]);

const addTag = () => {
  if (newTag.value.trim() !== "") {
    tags.value.push(newTag.value.trim());
    emit("input", tags.value);
    newTag.value = "";
  }
};

const removeTag = (index) => {
  tags.value.splice(index, 1);
  emit("input", tags.value);
};
</script>

<style scoped>
.tags-input {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  border: 1px solid #ccc;
  padding: 5px;
  border-radius: 5px;
}
.tags-input input {
  font-size: 14px;
  border: none;
  background-color: transparent;
  outline: none;
  flex: 1;
}
.tag {
  background-color: rgba(220, 15, 56, 0.501);
  color: white;
  padding: 3px;
  margin: 2px;
  border-radius: 6px;
  display: flex;
  align-items: center;
}
.tag span {
  font-size: 13px;
  padding: 0px 5px;
  font-size: 12px;
  cursor: pointer;
}

.ri-search-line {
  font-size: 17px;
}
</style>
