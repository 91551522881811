import { createRouter, createWebHistory } from "vue-router";
import InboxView from "../views/InboxView.vue";
import DashboardView from "@/views/DashboardView.vue";
import MonthDashboardView from "@/views/MonthDashboard.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: InboxView,
  },
  {
    path: "/login",
    name: "Login",
    component: InboxView,
  },
  {
    path: "/admin",
    name: "Dashboard - Admin",
    component: MonthDashboardView,
  },
  {
    path: "/inbox",
    name: "inbox - Main",
    component: InboxView,
  },
  {
    path: "/inbox/:id",
    name: "inbox - Current Inbox",
    component: InboxView,
  },
  {
    path: "/inbox/:id/dashboard",
    name: "inbox - Dashboard",
    component: DashboardView,
  },
  {
    path: "/inbox/:id/messages/:messageId?",
    name: "inbox - Messages",
    component: InboxView,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  next();
});

export default router;
