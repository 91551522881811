import { toast } from "vue3-toastify";

export class PopUpNotify {
  static displaySuccess({ message, position }) {
    toast(message, {
      position: position,
      autoClose: 2000,
      hideProgressBar: true,
      type: "success",
      toastStyle: {
        "--toastify-icon-color-error": "#c60d31",
        "--toastify-color-error": "#c60d31",
        fontFamily: "Fivo Sans Modern Regular",
      },
    });
  }

  static displayError({ message, position }) {
    toast(message, {
      position: position,
      autoClose: 5000,
      type: "error",
      toastStyle: {
        "--toastify-icon-color-error": "#c60d31",
        "--toastify-color-error": "#c60d31",
        fontFamily: "Fivo Sans Modern Regular",
      },
      progressStyle: {
        backgroundColor: "#c60d31",
      },
    });
  }

  static displayDefault({ message, position }) {
    toast(message, {
      position: position,
      autoClose: 1200,
      type: "default",
      hideProgressBar: true,
      toastStyle: {
        "--toastify-icon-color-error": "#c60d31",
        "--toastify-color-error": "#c60d31",
        fontFamily: "Fivo Sans Modern Regular",
      },
    });
  }
}
