<script setup>
import { provide } from "vue";
import { useRoute } from "vue-router";
import { useInbox } from "@/components/inbox/hooks/useInbox";

const route = useRoute();

const {
  current_inbox,
  mails,
  inboxBusinessClients,
  inboxBusinessUserMail,
  currentPage,
  calculateIndexes,
  startIndex,
  endIndex,
  currentFilter,
  nextPage,
  selectedMail,
  previousPage,
  fetchEmails,
  ready,
  handlePageSelected,
  getInboxBusinessClientEmails,
  getInboxBusinessClientById,
  inbox,
  updateInbox,
  emailBody,
  filterEmails,
  fetchMetaInfo,
  metaInfo,
  inboxMessage,
  inboxUserStatus,
  currentTotalMessages,
  selectedStoredMails,
  currentPageEmailIds,
  updateMails,
  signatures,
  batonPassEmails,
} = useInbox(route.params.id || localStorage.getItem("currentInbox"));

provide("inbox", {
  current_inbox,
  mails,
  inboxBusinessClients,
  inboxBusinessUserMail,
  currentPage,
  calculateIndexes,
  startIndex,
  endIndex,
  currentFilter,
  nextPage,
  selectedMail,
  previousPage,
  fetchEmails,
  ready,
  handlePageSelected,
  getInboxBusinessClientEmails,
  getInboxBusinessClientById,
  inbox,
  updateInbox,
  emailBody,
  filterEmails,
  fetchMetaInfo,
  metaInfo,
  inboxMessage,
  inboxUserStatus,
  currentTotalMessages,
  selectedStoredMails,
  currentPageEmailIds,
  updateMails,
  signatures,
  batonPassEmails,
});

const themeOverride = {
  Checkbox: {
    colorChecked: "#dc0f37FF",
    borderChecked: "1px solid #dc0f37",
    boxShadowFocus: "none",
    borderFocus: "1px solid #dc0f37",
    sizeLarge: "21px",
  },
};
</script>

<template>
  <n-config-provider :theme-overrides="themeOverride">
    <router-view />
  </n-config-provider>
</template>

<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  font-style: normal;
  text-decoration: none;
  text-transform: none;
  letter-spacing: normal;
  line-height: 1.55;
  color: #000000;
  font-family: "Fivo Sans Modern Regular";
}

/* CSS DO EDITOR DE TEXTO */
.ql-toolbar.ql-snow {
  border: none;
  box-sizing: border-box;
  font-family: "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  padding: 8px;
}

.ql-container.ql-snow {
  border: none;
  max-height: 300px;
  height: 300px;
}

.ql-editor td {
  border: none;
  padding: 2px 5px;
}
</style>
