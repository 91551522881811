<template>
  <div class="dashboard-container">
    <h1>Dados Mensais</h1>
    <div class="month-picker">
      <label for="month">Selecione o mês:</label>
      <select id="month" v-model="selectedMonth">
        <option v-for="month in months" :value="month.value" :key="month.value">
          {{ month.name }}
        </option>
      </select>

      <label for="year">Selecione o Ano:</label>
      <select id="year" v-model="selectedYear">
        <option v-for="year in years" :value="year" :key="year">
          {{ year }}
        </option>
      </select>
      <button @click="fetchMonthlyData">Buscar informações</button>
    </div>
    <KPIs :kpis="kpis" />
  </div>
</template>

<script setup>
import { ref, inject } from "vue";
import axios from "axios";
import KPIs from "../components/dashboard/components/KPIs.vue";
import { toast } from "vue3-toastify";
import config from "../config/environment.js";

const { inbox } = inject("inbox");

const years = [2024, 2025, 2026];

const months = [
  { name: "Janeiro", value: "janeiro", mm: "01" },
  { name: "Fevereiro", value: "fevereiro", mm: "02" },
  { name: "Março", value: "marco", mm: "03" },
  { name: "Abril", value: "abril", mm: "04" },
  { name: "Maio", value: "maio", mm: "05" },
  { name: "Junho", value: "junho", mm: "06" },
  { name: "Julho", value: "julho", mm: "07" },
  { name: "Agosto", value: "agosto", mm: "08" },
  { name: "Setembro", value: "setembro", mm: "09" },
  { name: "Outubro", value: "outubro", mm: "10" },
  { name: "Novembro", value: "novembro", mm: "11" },
  { name: "Dezembro", value: "dezembro", mm: "12" },
];

const selectedMonth = ref(months[0].value);
const selectedYear = ref(years[0]);

const getEmailMetrics = async (id, startDate, endDate) => {
  try {
    const response = await axios.get(
      `${config.GROWTH_DB}/inbox-emails/metrics/summary?startDate=${startDate}&endDate=${endDate}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "VjNMgnNt9JaRtDc2tcPDWdGzni13",
        },
      }
    );

    return response.data.data;
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

const getMonthlyMetricsAdapter = async (month, year) => {
  const monthObj = months.find((m) => m.value === month.toLowerCase());

  const startDate = `${year}-${monthObj.mm}-01`;
  const endDate = new Date(year, parseInt(monthObj.mm), 0)
    .toISOString()
    .split("T")[0];

  return await getEmailMetrics(null, startDate, endDate);
};

const kpis = ref({
  requests: 0,
  delivered: 0,
  opens: 0,
  unique_opens: 0,
  clicks: 0,
  unique_clicks: 0,
  unsubscribes: 0,
  bounces: 0,
  spam_reports: 0,
  blocks: 0,
  bounce_drops: 0,
  spam_report_drops: 0,
  unsubscribe_drops: 0,
  schedulings: 0,
});

const fetchMonthlyData = async () => {
  const monthNumber = (
    "0" +
    (months.findIndex((month) => month.value === selectedMonth.value) + 1)
  ).slice(-2);
  const shortYear = selectedYear.value.toString().slice(-2); // Pegar os últimos dois dígitos do ano
  const startDate = `${selectedYear.value}-${monthNumber}-01`;
  const endDate = new Date(selectedYear.value, monthNumber, 0)
    .toISOString()
    .split("T")[0]; // Último dia do mês

  console.log("Fetching data from API...");
  console.log("Start Date:", startDate);
  console.log("End Date:", endDate);

  let id;

  try {
    id = toast.loading("Buscando dados...", {
      position: "top-right",
      toastStyle: {
        fontFamily: "Fivo Sans Modern Regular",
      },
    });

    const response = await axios.get(
      "https://api.sendgrid.com/v3/categories/stats",
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization:
            "Bearer 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", // substitua pelo seu token real
        },
        params: {
          categories: `${selectedMonth.value}-${shortYear}`,
          start_date: startDate,
          end_date: endDate,
          aggregated_by: "day",
        },
      }
    );

    toast.update(id, {
      render: "Métricas buscadas com sucesso!",
      type: "success",
      isLoading: false,
      autoClose: 5000,
      toastStyle: {
        fontFamily: "Fivo Sans Modern Regular",
      },
      progressStyle: {
        backgroundColor: "green",
      },
    });

    console.log("API Response:", response.data);

    const data = response.data;
    const labels = data.map((item) => item.date);
    const opens = data.map((item) => item.stats[0].metrics.opens);
    const uniqueOpens = data.map((item) => item.stats[0].metrics.unique_opens);

    // Calculate KPIs
    const newKpis = {
      requests: 0,
      delivered: 0,
      opens: 0,
      unique_opens: 0,
      clicks: 0,
      unique_clicks: 0,
      unsubscribes: 0,
      bounces: 0,
      spam_reports: 0,
      blocks: 0,
      bounce_drops: 0,
      spam_report_drops: 0,
      unsubscribe_drops: 0,
    };

    data.forEach((item) => {
      const metrics = item.stats[0].metrics;
      newKpis.requests += metrics.requests;
      newKpis.delivered += metrics.delivered;
      newKpis.opens += metrics.opens;
      newKpis.unique_opens += metrics.unique_opens;
      newKpis.clicks += metrics.clicks;
      newKpis.unique_clicks += metrics.unique_clicks;
      newKpis.unsubscribes += metrics.unsubscribes;
      newKpis.bounces += metrics.bounces;
      newKpis.spam_reports += metrics.spam_reports;
      newKpis.blocks += metrics.blocks;
      newKpis.bounce_drops += metrics.bounce_drops;
      newKpis.spam_report_drops += metrics.spam_report_drops;
      newKpis.unsubscribe_drops += metrics.unsubscribe_drops;
    });

    const { schedulings_count: schedulings } = await getMonthlyMetricsAdapter(
      selectedMonth.value,
      selectedYear.value
    );

    newKpis.schedulings = schedulings;

    kpis.value = newKpis;

    console.log("KPIs:", kpis.value);
  } catch (error) {
    console.error("Error fetching data:", error);
    toast.update(id, {
      render:
        "Erro ao buscar métricas! Pode ser que não haja registros para o mês/ano selecionado",
      type: "error",
      isLoading: false,
      autoClose: 5000,
      toastStyle: {
        fontFamily: "Fivo Sans Modern Regular",
      },
    });
  }
};
</script>

<style scoped>
.dashboard-container {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 10px;
  height: 100vh;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

h1 {
  text-align: center;
  color: #333;
  margin-bottom: 20px;
}

.month-picker {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

label {
  margin: 0 10px;
  color: #333;
}

select {
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

button {
  margin-left: 10px;
  padding: 5px 10px;
  border: none;
  background-color: #ff4d4d;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #e60000;
}

.chart-container {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
</style>
